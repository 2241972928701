import React from "react"

import TelephoneNumber from "./telephoneNumber"
import Dropdown, { DropdownText } from "./dropdown"
import Logo from "../image-components/logo"
import MobileNav from "./mobileNav"
import NavLink from "./navLink"

// There is some code here which is repeated in NavLink.
// I do not wish to modularize it.

const Header = ({ siteTitle }) => (
  <header className="header">
    <NavLink to="/">
      <span className="header__logo-box">
        <Logo />
      </span>
    </NavLink>
    <div className="header__content">
      <div className="header__title-box">
        <h1 className="header__title">{siteTitle}</h1>
        <h2 className="header__telephone-and-slogan-box">
          <TelephoneNumber />{" "}
          <span className="header__slogan">
            ... Best Price{" "}
            <span className="header__guaranteed-text">Guaranteed!</span>
          </span>
        </h2>
      </div>
      <div className="header__booking-button-wrapper">
        <button
          data-token="c5cefb46a92d42eb8996ee949374d551"
          data-orgname="Rather-Be-Plumbing"
          className="hcp-button"
          onClick={() => {
            eval("HCPWidget.openModal()")
          }}
        >
          <span className="header__booking-button-text--long">BOOK AN APPOINTMENT</span>
          <span className="header__booking-button-text--short">BOOK NOW</span>
        </button>
      </div>
      <nav className="header__nav">
        <NavLink className="header__hideable-link" to="/">Home</NavLink>
        <Dropdown to="/services" title="Services">
          <NavLink to="/water-heater-service" gutterBottom>
            Water Heater Service
          </NavLink>
          <NavLink to="/drain-cleaning" gutterBottom>
            Drain Cleaning
          </NavLink>
          <NavLink to="/furnace-and-air-handlers" gutterBottom>
            Furnace & Air Handlers
          </NavLink>
          <NavLink
            to="/perimeter-drain-replacement-and-water-proofing"
            gutterBottom
          >
            Perimeter Drains & Water Proofing
          </NavLink>
          <NavLink to="/fireplace-installs" gutterBottom>
            Fireplace Installs
          </NavLink>
        </Dropdown>
        <NavLink to="/strata">Strata</NavLink>
        <NavLink className="header__hideable-link" to="/about">About</NavLink>
        <Dropdown title="Covid-19" expandLeft>
          <DropdownText>
            <p>
              We’re here for you. Things are changing fast, but one thing stays
              the same: You and your families, our employees and their families’
              health are our top priority.
            </p>
            <p>
              We are open 24/7 to provide full plumbing and heating services for
              our customers. We have implemented a ‘No Contact’ safety protocol
              during this time. During this time we are also offing Virtual
              Estimates.
            </p>
            <p>
              Please call direct: <TelephoneNumber />
            </p>
            <p>Be safe, Victoria. This too shall pass!</p>
          </DropdownText>
        </Dropdown>
      </nav>
    </div>
    <MobileNav />
  </header>
)

export default Header
