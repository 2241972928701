import React, { useState } from 'react';
import NavLink from './navLink';
import TelephoneNumber from './telephoneNumber';

const MobileNav = () => {
  const [open, setOpen] = useState(false);
  const [covidOpen, setCovidOpen] = useState(false);
  const toggle = () => {
    setOpen(!open);
    setCovidOpen(false);
  };
  const toggleCovid = () => setCovidOpen(!covidOpen);

  const buttonClass = `mobile-nav__button${
    open ? '' : ' mobile-nav__button--closed'
  }`;
  const menuClass = `mobile-nav__menu${open ? '' : ' mobile-nav__menu--closed'}`;
  const covidMenuClass = `mobile-nav__menu mobile-nav__menu--covid${
    covidOpen ? '' : ' mobile-nav__menu--closed mobile-nav__menu--covid--closed'
  }`;

  const closeMenu = () => setOpen(false);

  return (
    <div className="mobile-nav">
      <button className={buttonClass} onClick={toggle}>
        MENU
      </button>
      <div className={menuClass}>
        <ul className="mobile-nav__list">
          <li className="mobile-nav__list-item">
            <NavLink onClick={closeMenu} to="/">
              Homepage
            </NavLink>
          </li>
        </ul>
        <div className="mobile-nav__section-heading">SERVICES</div>
        <ul className="mobile-nav__list">
          <li className="mobile-nav__list-item">
            <NavLink onClick={closeMenu} to="/water-heater-service">
              Water Heater Service
            </NavLink>
          </li>
          <li className="mobile-nav__list-item">
            <NavLink onClick={closeMenu} to="/drain-cleaning">
              Drain Cleaning
            </NavLink>
          </li>
          <li className="mobile-nav__list-item">
            <NavLink onClick={closeMenu} to="/furnace-and-air-handlers">
              Furnace & Air Handlers
            </NavLink>
          </li>
          <li className="mobile-nav__list-item">
            <NavLink
              onClick={closeMenu}
              to="/perimeter-drain-replacement-and-water-proofing"
            >
              Perimeter Drains & Water Proofing
            </NavLink>
          </li>
          <li className="mobile-nav__list-item">
            <NavLink onClick={closeMenu} to="/fireplace-installs">
              Fireplace Installs
            </NavLink>
          </li>
          <li className="mobile-nav__list-item">
            <NavLink onClick={closeMenu} to="/services">
              View All Services
            </NavLink>
          </li>
        </ul>
        <div className="mobile-nav__section-heading">COMPANY & MORE</div>
        <ul className="mobile-nav__list">
          <li className="mobile-nav__list-item">
            <NavLink onClick={closeMenu} to="/strata">
              Strata Maintenance
            </NavLink>
          </li>
          <li className="mobile-nav__list-item">
            <NavLink onClick={toggleCovid} to="#">
              Covid 19 Policy
            </NavLink>
          </li>
          <li className="mobile-nav__list-item">
            <NavLink onClick={closeMenu} to="/about">
              About Us
            </NavLink>
          </li>
          <li className="mobile-nav__list-item">
            <NavLink onClick={closeMenu} to="/coupons">
              Coupons
            </NavLink>
          </li>
          <li className="mobile-nav__list-item">
            <NavLink onClick={closeMenu} to="/privacy">
              Privacy Policy
            </NavLink>
          </li>
        </ul>
      </div>
      <div className={covidMenuClass}>
        <ul className="mobile-nav__list">
          <li className="mobile-nav__list-item">
            <NavLink onClick={toggleCovid} to="#">
              Go Back
            </NavLink>
          </li>
        </ul>
        <div className="mobile-nav__section-heading">Our Covid-19 Policy</div>
        <ul className="mobile-nav__list">
          <li className="mobile-nav__list-item">
            <p>
              We’re here for you. Things are changing fast, but one thing stays
              the same: You and your families, our employees and their families’
              health are our top priority.
            </p>
            <br />
            <p>
              We are open 24/7 to provide full plumbing and heating services for
              our customers. We have implemented a ‘No Contact’ safety protocol
              during this time. During this time we are also offing Virtual
              Estimates.
            </p>
            <br />
            <p>
              Please call direct:
              {' '}
              <TelephoneNumber>250-589-8555</TelephoneNumber>
            </p>
            <br />
            <p>Be safe, Victoria. This too shall pass!</p>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default MobileNav;
