import React, { useState } from 'react';
import { Link } from 'gatsby';
import { useLocation } from '@reach/router';

import chevron from '../images/chevron.png';
import yellowChevron from '../images/yellow-chevron.png';

const Dropdown = ({
  children, title, to = '#', expandLeft,
}) => {
  const [hovering, setHovering] = useState(false);
  const close = () => setHovering(false);
  const open = () => setHovering(true);

  const { pathname } = useLocation();
  const c = `dropdown${pathname === to ? ' dropdown--active' : ''}${
    hovering ? ' dropdown--hovered' : ''
  }${expandLeft ? ' dropdown--expand-left' : ''}`;

  return (
    <Link to={to} className={c} onMouseEnter={open} onMouseLeave={close}>
      {title}
      {' '}
      <img
        src={pathname === to ? yellowChevron : chevron}
        alt="dropdown arrow"
        className="dropdown__chevron"
      />
      <div
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        className="dropdown__menu"
      >
        {children}
      </div>
    </Link>
  );
};

const DropdownLink = ({ to, children }) => {
  const { pathname } = useLocation();
  const c = `dropdown__nav-link${
    pathname === to ? ' dropdown__nav-link--active' : ''
  }`;

  return (
    <Link to={to} className={c}>
      {children}
    </Link>
  );
};

const DropdownText = ({ children }) => (
  <p className="dropdown__text">{children}</p>
);

export default Dropdown;

export { DropdownLink, DropdownText };
