import React from "react"
import { Link } from "gatsby"
import { useLocation } from "@reach/router"

const NavLink = ({ to, children, gutterBottom, onClick, className }) => {
  const { pathname } = useLocation()
  let c = `nav-link${pathname === to ? " nav-link--active" : ""}${
    gutterBottom ? " nav-link--gutter-bottom" : ""
  }`
  if (className) c += ` ${className}`

  return (
    <Link to={to} className={c} onClick={onClick}>
      {children}
    </Link>
  )
}

export default NavLink
