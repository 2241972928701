import React from "react"
import { Link } from "gatsby"
import TelephoneNumber from "./telephoneNumber"
import Logo from "../image-components/logo"

const Header = ({ animateHeader, siteTitle }) => (
  <footer className="footer">
    <ul className="footer__column">
      <li className="footer__item">
        <Link to="/fireplace-installs">Fireplace Installs</Link>
      </li>
      <li className="footer__item">
        <Link to="/water-heater-service">Water Heater Service</Link>
      </li>
      <li className="footer__item">
        <Link to="/furnace-and-air-handlers">Furnace & Air Handlers</Link>
      </li>
      <li className="footer__item">
        <Link to="/privacy">Privacy Policy</Link>
      </li>
    </ul>
    <ul className="footer__column">
      <li className="footer__item">
        <Link to="/drain-cleaning">Drain Cleaning</Link>
      </li>
      <li className="footer__item">
        <Link to="/perimeter-drain-replacement-and-water-proofing">
          Perimeter Drains & Water Proofing
        </Link>
      </li>
      <li className="footer__item">
        <Link to="/services">All Services</Link>
      </li>
    </ul>
    <ul className="footer__column">
      <li className="footer__item">
        <Link to="/strata">Strata</Link>
      </li>
      <li className="footer__item">
        <Link to="/coupons">Coupons</Link>
      </li>
      <li className="footer__item">
        <Link to="/about">About</Link>
      </li>
    </ul>
    <div className="footer__last-column-box">
      <ul className="footer__column">
        <li className="footer__item">Rather Be Plumbing Ltd.</li>
        <li className="footer__item">
          756 Chesterlea Road, Victoria, BC V8X3R2
        </li>
        <li className="footer__item">
          <TelephoneNumber /> •{" "}
          <a href="mailto:ratherbeplumbing@gmail.com">
            ratherbeplumbing@gmail.com
          </a>
        </li>
        <li />
      </ul>
      <div className="footer__logo">
        <Logo />
      </div>
    </div>
  </footer>
)

export default Header
