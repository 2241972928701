/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { throttle } from "lodash/function"

import Header from "./header"
import Footer from "./footer"
import "../styles/main.sass"

export default class Layout extends React.Component {
  render() {
    const { children } = this.props

    return (
      <>
        <Header
          siteTitle="Rather Be Plumbing"
          animateHeader={true}
        />
        <main>
          {children}
        </main>
        <Footer />
      </>
    )
  }
}
